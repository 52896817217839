export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOG_OUT = 'LOG_OUT'
export const LOAD_PROFILE = 'LOAD_PROFILE'

export const HOME_VIDEOS_SUCCESS = 'HOME_VIDEOS_SUCCESS'
export const HOME_VIDEOS_FAIL = 'HOME_VIDEOS_FAIL'
export const HOME_VIDEOS_REQUEST = 'HOME_VIDEOS_REQUEST'

export const SELECTED_VIDEO_REQUEST = 'SELECTED_VIDEO_REQUEST'
export const SELECTED_VIDEO_SUCCESS = 'SELECTED_VIDEO_SUCCESS'
export const SELECTED_VIDEO_FAIL = 'SELECTED_VIDEO_FAIL'

export const CHANNEL_DETAILS_REQUEST = 'CHANNEL_DETAILS_REQUEST'
export const CHANNEL_DETAILS_SUCCESS = 'CHANNEL_DETAILS_SUCCESS'
export const CHANNEL_DETAILS_FAIL = 'CHANNEL_DETAILS_FAIL'

export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'

export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_FAIL = 'COMMENT_LIST_FAIL';
export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';


export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL'

export const RELATED_VIDEO_REQUEST = 'RELATED_VIDEO_REQUEST'
export const RELATED_VIDEO_SUCCESS = 'RELATED_VIDEO_SUCCESS'
export const RELATED_VIDEO_FAIL = 'RELATED_VIDEO_FAIL'

export const SEARCHED_VIDEO_REQUEST = 'SEARCHED_VIDEO_REQUEST'
export const SEARCHED_VIDEO_SUCCESS = 'SEARCHED_VIDEO_SUCCESS'
export const SEARCHED_VIDEO_FAIL = 'SEARCHED_VIDEO_FAIL'

export const SUBSCRIPTIONS_CHANNEL_REQUEST = 'SUBSCRIPTIONS_CHANNEL_REQUEST'
export const SUBSCRIPTIONS_CHANNEL_SUCCESS = 'SUBSCRIPTIONS_CHANNEL_SUCCESS'
export const SUBSCRIPTIONS_CHANNEL_FAIL = 'SUBSCRIPTIONS_CHANNEL_FAIL'


export const CHANNEL_VIDEOS_REQUEST = 'CHANNEL_VIDEOS_REQUEST'
export const CHANNEL_VIDEOS_SUCCESS = 'CHANNEL_VIDEOS_SUCCESS'
export const CHANNEL_VIDEOS_FAIL = 'CHANNEL_VIDEOS_FAIL'



export const LIKED_VIDEOS_REQUEST = 'LIKED_VIDEOS_REQUEST'
export const LIKED_VIDEOS_SUCCESS = 'LIKED_VIDEOS_SUCCESS'
export const LIKED_VIDEOS_FAIL = 'LIKED_VIDEOS_FAIL'

export const ALL_PLAYLIST_REQUEST = 'ALL_PLAYLIST_REQUEST'
export const ALL_PLAYLIST_SUCCESS = 'ALL_PLAYLIST_SUCCESS'
export const ALL_PLAYLIST_FAIL = 'ALL_PLAYLIST_FAIL'

export const PLAYLIST_VIDOES_REQUEST = 'PLAYLIST_VIDOES_REQUEST'
export const PLAYLIST_VIDOES_SUCCESS = 'PLAYLIST_VIDOES_SUCCESS'
export const PLAYLIST_VIDOES_FAIL = 'PLAYLIST_VIDOES_FAIL'



export const MAKE_LIKE_REQUEST = 'MAKE_LIKE_REQUEST'
export const MAKE_LIKE_SUCCESS = 'MAKE_LIKE_SUCCESS'
export const MAKE_LIKE_FAIL = 'MAKE_LIKE_FAIL'


export const GET_RATE_REQUEST = 'GET_RATE_REQUEST'
export const GET_RATE_SUCCESS = 'GET_RATE_SUCCESS'
export const GET_RATE_FAIL = 'GET_RATE_FAIL'


export const SET_SUBSCRIPTION_REQUEST = 'SET_SUBSCRIPTION_REQUEST'
export const SET_SUBSCRIPTION_SUCCESS = 'SET_SUBSCRIPTION_SUCCESS'
export const SET_SUBSCRIPTION_FAIL = 'SET_SUBSCRIPTION_FAIL'


export const DEL_SUBSCRIPTION_REQUEST = 'DEL_SUBSCRIPTION_REQUEST'
export const DEL_SUBSCRIPTION_SUCCESS = 'DEL_SUBSCRIPTION_SUCCESS'
export const DEL_SUBSCRIPTION_FAIL = 'DEL_SUBSCRIPTION_FAIL'

export const GET_CHANNEL_REQUEST = 'GET_CHANNEL_REQUEST'
export const GET_CHANNEL_SUCCESS = 'GET_CHANNEL_SUCCESS'
export const GET_CHANNEL_FAIL = 'GET_CHANNEL_FAIL'


export const GET_COMMUNITY_REQUEST = 'GET_COMMUNITY_REQUEST'
export const GET_COMMUNITY_SUCCESS = 'GET_COMMUNITY_SUCCESS'
export const GET_COMMUNITY_FAIL = 'GET_COMMUNITY_FAIL'


export const GET_SHORTS_REQUEST = 'GET_SHORTS_REQUEST'
export const GET_SHORTS_SUCCESS = 'GET_SHORTS_SUCCESS'
export const GET_SHORTS_FAIL = 'GET_SHORTS_FAIL'

export const GET_CHANNEL_PLAYLIST_REQUEST = 'GET_CHANNEL_PLAYLIST_REQUEST'
export const GET_CHANNEL_PLAYLIST_SUCCESS = 'GET_CHANNEL_PLAYLIST_SUCCESS'
export const GET_CHANNEL_PLAYLIST_FAIL = 'GET_CHANNEL_PLAYLIST_FAIL'


export const GET_CHANNEL_PLAYLIST_SCREEN_REQUEST = 'GET_CHANNEL_PLAYLIST_SCREEN_REQUEST'
export const GET_CHANNEL_PLAYLIST_SCREEN_SUCCESS = 'GET_CHANNEL_PLAYLIST_SCREEN_SUCCESS'
export const GET_CHANNEL_PLAYLIST_SCREEN_FAIL = 'GET_CHANNEL_PLAYLIST_SCREEN_FAIL'


export const GET_VIDEOS_KEYWORD_REQUEST = 'GET_VIDEOS_KEYWORD_REQUEST'
export const GET_VIDEOS_KEYWORD_SUCCESS = 'GET_VIDEOS_KEYWORD_SUCCESS'
export const GET_VIDEOS_KEYWORD_FAIL = 'GET_VIDEOS_KEYWORD_FAIL'
