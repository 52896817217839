export const api = 'AIzaSyCfKcCdUn_d3SZEjhzYj7QefOZQOp8GRWg'
export const REACT_APP_YT_API_KEY = 'AIzaSyCfKcCdUn_d3SZEjhzYj7QefOZQOp8GRWg'
export const REACT_APP_YT_API_AUTHKEY = '843622392006-tavqbqj8rqcov21aandbulkob3ddl9e8.apps.googleusercontent.com'


// AIzaSyDl7uKPV0cop3usQ0GfWVzAAJuVDArFDsA



// AIzaSyCfKcCdUn_d3SZEjhzYj7QefOZQOp8GRWg main

// AIzaSyBBZ6-EhpQDePTja__rL0kToRFliJZxcXE  side